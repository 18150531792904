import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Content from "../../components/content"
import Brands from "../../components/brands"

const ClothingBrandsPage = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  const title = data.markdownRemark.frontmatter.title;
  const caption = data.markdownRemark.frontmatter.caption;
  const brandsContent = data.markdownRemark.frontmatter.brands;
  const body = data.markdownRemark.html;

  return (
    <Layout progressEnabled={true} navSolid={true}>
      <SEO title={title} />
      <Content title={title} crumbs={crumbs} caption={caption} body={body} centered={true} />
      <Brands brandsContent={brandsContent} />
    </Layout>
  )
}

export const query = graphql`
query {
  markdownRemark(frontmatter: { template_key: { eq: "clothing-brands" } }) {
    frontmatter {
      title
      caption
      brands {
        title
        subtitle
        caption
        button_text
        url
        catalog_button_text
        catalog_url
        image_one {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_two {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_three {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    html
  }
}
`

export default ClothingBrandsPage
