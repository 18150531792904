import React from "react"
import { Fade } from "react-awesome-reveal";
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import List from "../components/listItems"

const Content = ({ title, crumbs, caption, listItems, body, centered }) => {
  if (centered) {
    return (
      <section className="content content-centered">
        <div className="container">
          <div className="row">
            <div className="col col-full">
              <Fade triggerOnce>
                <h1>{title}</h1>
                <Breadcrumb crumbs={crumbs} crumbSeparator=" / " />
                <p className="lead">{caption}</p>
                <div dangerouslySetInnerHTML={{ __html: body }} />
              </Fade>
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col col-full">
              <Fade triggerOnce>
                <h1>{title}</h1>
                <Breadcrumb crumbs={crumbs} crumbSeparator=" / " />
              </Fade>
            </div>
            <div className="col col-two-third">
              <Fade triggerOnce>
                <p className="lead">{caption}</p>
                <div dangerouslySetInnerHTML={{ __html: body }} />
              </Fade>
            </div>
            <div className="col col-one-third">
              {listItems &&
                <Fade triggerOnce>
                  <List listItems={listItems} />
                </Fade>
              }
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Content
