import React from "react"
import BackgroundImage from 'gatsby-background-image'

const Brands = ({ brandsContent }) => {

  return (
    <section className="brands">
      {brandsContent.map((brand, index) =>
        <section className="brand" key={index}>
          <div className="container">
            <div className="row">
              <div className="col">
                <h2>{brand.title}</h2>
                <p className="lead">{brand.subtitle}</p>
              </div>
              <div className="col">
                <p>{brand.caption}</p>
              </div>
            </div>
          </div>
          <div className="images">
            <div className="row">
              <div className="col col-one-third">
                <BackgroundImage Tag="section" className="brand-image" fluid={brand.image_one.childImageSharp.fluid} backgroundColor={`#000`} />
              </div>
              <div className="col col-one-third">
                <BackgroundImage Tag="section" className="brand-image" fluid={brand.image_two.childImageSharp.fluid} backgroundColor={`#000`} />
              </div>
              <div className="col col-one-third">
                <BackgroundImage Tag="section" className="brand-image" fluid={brand.image_three.childImageSharp.fluid} backgroundColor={`#000`} />
              </div>
            </div>
          </div>
          <div className="text-center">
            <a className="button" href={brand.url} target="_blank" rel="noreferrer">
              {brand.button_text} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><polygon fill="#fff" points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>
            </a>
            {brand.catalog_button_text &&
              <a className="button btn-bordered" href={brand.catalog_url} target="_blank" rel="noreferrer">{
                brand.catalog_button_text} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><polygon points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>
              </a>
            }
          </div>
        </section>
      )}
    </section>
  );
}

export default Brands
