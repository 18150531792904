import React from "react"
import { Fade } from "react-awesome-reveal";

const ListItems = ({ listItems }) => (
  <Fade triggerOnce>
    <div className="item-list">
      <ul>
        {listItems.map((item, index) =>
          <li key={index}>
            <div dangerouslySetInnerHTML={{ __html: item.icon }} />
            {item.title}
          </li>
        )}
      </ul>
    </div>
  </Fade>
)

export default ListItems
